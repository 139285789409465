import Vue from 'vue'
import Router from 'vue-router'
import Games from '@/views/Games';

import Clues from '@/views/Clues'
import ResolveCase from "@/views/ResolveCase";
import store from "../store";
import { createPinia } from 'pinia';
import { useYakuzaStore } from '@/store/yakuza';

import MaestroRoot from "@/views/maestro/Root";
import MaestroHome from '@/views/maestro/Home';
import MaestroPhone from '@/views/maestro/Phone';
import Voices from '@/views/maestro/Voices';
import MaestroDialPhone from '@/views/maestro/DialPhone';
import MaestroSafe from '@/views/maestro/Safe';
import MaestroAudioPlayer from '@/views/maestro/AudioPlayer';
import MaestroSecurity from '@/views/maestro/Security';
import MaestroPolice from '@/views/maestro/Police';
import MaestroPoliceHome from '@/views/maestro/PoliceHome';
import MaestroLaboratory from '@/views/maestro/Laboratory';
import MaestroBank from '@/views/maestro/Bank';

import MaestroEvent from "../views/maestro/Event";

import ColossusRoot from "../views/colossus/Root";
import ColossusHome from "../views/colossus/Home";
import ColossusCabins from "../views/colossus/Cabins";
import ColossusDarkweb from "../views/colossus/Darkweb";
import ColossusWebmail from "../views/colossus/Webmail";
import ColossusWebmailReader from "../views/colossus/WebmailReader";
import ColossusInterpolice from "../views/colossus/Interpolice";

import ColossusEvent from "../views/colossus/Event";

import mykonosRoot from "../views/mykonos/Root";
import mykonosHome from "../views/mykonos/Home";
import mykonosResearch from "../views/mykonos/Research";
import mykonosResearchHome from "../views/mykonos/ResearchHome";
import mykonosLuxuryEstate from "../views/mykonos/LuxuryEstate";
import mykonosPalaceHotel from "../views/mykonos/PalaceHotel";
import mykonosHospital from "../views/mykonos/Hospital";
import mykonosCasino from "../views/mykonos/Casino";
import mykonosPoliceStation from "../views/mykonos/PoliceStation";
import mykonosSanGiovani from "../views/mykonos/SanGiovani";
import mykonosAirport from "../views/mykonos/Airport";
import mykonosNowhere from "../views/mykonos/Nowhere";
import mykonosFlightAway from "../views/mykonos/FlightAway";
import mykonosBlog from "../views/mykonos/Blog";
import mykonosChronaki from "../views/mykonos/ChronakiBeachClub";
import mykonosArtemis from "../views/mykonos/ArtemisClub";

import ghostsHome from "../views/ghosts/Home";
import ghostsRoot from "../views/ghosts/Root";
import ghostsGhostHunters from "../views/ghosts/GhostHunters";
import ghostsGhostHuntersNews from "../views/ghosts/GhostHuntersNews";
import ghostsGhostHuntersHistory from "../views/ghosts/GhostHuntersHistory";
import ghostsGhostHuntersDemons from "../views/ghosts/GhostHuntersDemons";
import ghostsHorizon from "../views/ghosts/Horizon";
import ghostsDaily from "../views/ghosts/DailyYorkshire";
import ghostsPolice from "../views/ghosts/Police";
import ghostsOuija from "../views/ghosts/Ouija";
import ghostsTarot from "../views/ghosts/Tarot";
import ghostsHotel from "../views/ghosts/Hotel";
import ghostsTouristOffice from "../views/ghosts/TouristOffice";
import ghostsTouristOfficeHome from "../views/ghosts/TouristOfficeHome";
import ghostsTouristOfficePlaces from '../views/ghosts/TouristOfficePlaces';
import ghostsTouristOfficeShop from '../views/ghosts/TouristOfficeShop';
import ghostsTouristOfficeBune from '../views/ghosts/BuneSecretPage';

import egyptHome from "../views/egypt/Home";
import egyptRoot from "../views/egypt/Root";
import egyptSecurity from "../views/egypt/Security";
import egyptPolice from "../views/egypt/Police";
import egyptSuitcase from "../views/egypt/Suitcase";
import egyptPhone from "../views/egypt/Phone";
import egyptKarnak from '../views/egypt/Karnak';
import egyptWebmail from '../views/egypt/Webmail';
import egyptWebmailReader from '../views/egypt/WebmailReader';
import egyptCall from '../views/egypt/Call';
import egyptResolve from '../views/egypt/Resolve';

import yakuzaHome from "../views/yakuza/Home";
import yakuzaRoot from "../views/yakuza/Root";
import yakuzaMetro from "../views/yakuza/Metro";
import yakuzaResolve from "../views/yakuza/Resolve";
import yakuzaAudio from "../views/yakuza/Audio";
import yakuzaHacking from "../views/yakuza/Hacking";
import yakuzaCall from "../views/yakuza/Call";
import yakuzaElevator from "../views/yakuza/Elevator";
import yakuzaSafe from "../views/yakuza/Safe";
import yakuzaArcade from "../views/yakuza/Arcade";

import config from "../config";
import Cases from "@/enums/Cases";

function isGameAvailable(gameId) {
  try {
    if (process.env.VUE_APP_COMINGSOON_GAMES) {
      const comingSoonGames = process.env.VUE_APP_COMINGSOON_GAMES.split(",");

      if (comingSoonGames.length > 0 && comingSoonGames.indexOf(gameId) !== -1)
        return false;
    }

    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

function getSectionFromRoute(sections, route) {
  let section = undefined;

  sections.forEach(s => {
    if (!section ) {
      if (s.route?.name === route.name)
        section = s;
      else if (s.subsections) {
        s.subsections.forEach(ss => {
          if (ss.route?.name === route.name)
            section = ss;
        })
      }
    }
  });

  console.log('target section', section);
  return section;
}

if (!store.getters.initialized)
  store.commit("initializeStore", config);

const pinia = createPinia();
const yakuzaStore = useYakuzaStore(pinia);
if(!yakuzaStore.initialized) {
  yakuzaStore.initialize(config);
}


Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'games',
      component: Games
    },
    {
      path: '/maestro/',
      component: MaestroRoot,
      beforeEnter: async (to, from, next) => {
        if (!await isGameAvailable("maestro"))
          next({name: "games"});
        else
         next();
      },
      children: [
        {
          path: "",
          name: "maestro-home",
          component: MaestroHome
        },
        {
          path: 'phone',
          name: 'maestro-phone',
          component: MaestroPhone,
        },
        {
          path: 'dialphone',
          name: 'maestro-dialphone',
          component: MaestroDialPhone,
        },
        {
          path: 'safe',
          name: 'maestro-safe',
          component: MaestroSafe
        },
        {
          path: 'playlist',
          name: 'maestro-playlist',
          component: MaestroAudioPlayer
        },
        {
          path: 'voices',
          name: 'maestro-voices',
          component: Voices,
        },
        {
          path: 'security',
          name: 'maestro-security',
          component: MaestroSecurity
        },
        {
          path: 'police',
          component: MaestroPolice,
          children: [
            {
              path: '',
              name: 'maestro-police-home',
              component: MaestroPoliceHome,
            },
            {
              path: 'laboratory',
              name: 'maestro-laboratory',
              component: MaestroLaboratory,
              meta: {
                restrictedTo: 'police'
              }
            },
            {
              path: 'bank',
              name: 'maestro-bank',
              component: MaestroBank,
              meta: {
                restrictedTo: 'police'
              }
            },
          ]
        },
        {
          path: 'clues',
          name: 'maestro-clues',
          component: Clues,
        },
        {
          path: 'resolve',
          name: 'maestro-resolve',
          component: ResolveCase,
        },
        {
          path: "event",
          name: "maestro-event",
          component: MaestroEvent,
        },    
      ]
    },
    {
      path: '/colossus/',
      component: ColossusRoot,
      beforeEnter: async (to, from, next) => {
        if (!await isGameAvailable("colossus"))
          next({name: "games"});
        else
          next();
      },
      children: [
        {
          path: "",
          name: "colossus-home",
          component: ColossusHome
        },
        {
          path: "cabins/:cabinID",
          name: "colossus-cabins",
          component: ColossusCabins,
          props: true
        },
        {
          path: "darkweb",
          name: "colossus-darkweb",
          component: ColossusDarkweb,
        },      
        {
          path: "webmail",
          name: "colossus-webmail",
          component: ColossusWebmail,
        },
        {
          path: "webmail/:guid",
          name: "colossus-webmail-reader",
          component: ColossusWebmailReader,
          props: true
        },
        {
          path: "interpolice",
          name: "colossus-interpolice",
          component: ColossusInterpolice,
          props: true
        },
        {
          path: 'clues',
          name: 'colossus-clues',
          component: Clues,
        },
        {
          path: 'resolve',
          name: 'colossus-resolve',
          component: ResolveCase,
        },
        {
          path: "event",
          name: "colossus-event",
          component: ColossusEvent,
        },          
      ]
    },
    { path: '/mikonos/', redirect: '/mykonos/' },
    {
      path: '/mykonos/',
      component: mykonosRoot,
      beforeEnter: async (to, from, next) => {
        if (!await isGameAvailable("mykonos"))
          next({name: "games"});
        else
          next();
      },
      children: [
        {
          path: "",
          name: "mykonos-home",
          component: mykonosHome
        },
        {
          path: 'research',
          component: mykonosResearch,
          children: [
            {
              path: '',
              name: 'mykonos-research-home',
              component: mykonosResearchHome,
            },
            {
              path: 'luxury-estate',
              name: 'mykonos-luxury-estate',
              component: mykonosLuxuryEstate,
            },
            {
              path: 'palace-hotel',
              name: 'mykonos-palace-hotel',
              component: mykonosPalaceHotel,
            },
            {
              path: 'hospital',
              name: 'mykonos-hospital',
              component: mykonosHospital,
            },
            {
              path: 'casino',
              name: 'mykonos-casino',
              component: mykonosCasino,
            },
            {
              path: 'police-station',
              name: 'mykonos-police-station',
              component: mykonosPoliceStation,
            },
            {
              path: 'san-giovani',
              name: 'mykonos-san-giovani',
              component: mykonosSanGiovani,
            },
            {
              path: 'airport',
              name: 'mykonos-airport',
              component: mykonosAirport,
            },
            {
              path: 'chronaki-beach-club',
              name: 'mykonos-chronaki-beach-club',
              component: mykonosChronaki,
            },
            {
              path: 'artemis-club',
              name: 'mykonos-artemis-club',
              component: mykonosArtemis,
            },
            {
              path: 'nowhere',
              name: 'mykonos-nowhere',
              component: mykonosNowhere
            }
          ]
        },
        {
          path: "voices",
          name: "mykonos-voices",
          component: Voices,
          beforeEnter: (to, from, next) => {
            if (store.getters.mykonosVoicesLocked)
              next({name: "mykonos-home"});
            else
              next();
          }
        },
        {
          path: "flightaway",
          name: "mykonos-flightaway",
          component: mykonosFlightAway
        },
        {
          path: "blog",
          name: "mykonos-blog",
          component: mykonosBlog
        },
        {
          path: 'clues',
          name: 'mykonos-clues',
          component: Clues,
        },
        {
          path: 'resolve',
          name: 'mykonos-resolve',
          component: ResolveCase,
        }
      ]
    },
    {
      path: '/ghosts/',
      component: ghostsRoot,
      props: { gameID: Cases.GHOSTS },
      beforeEnter: async (to, from, next) => {
        if (!await isGameAvailable("ghosts"))
          next({name: "games"});
        else {
          const targetSection = getSectionFromRoute(store.state.ghosts.sections, to);

          if (targetSection?.disabled ?? false)
            next('/ghosts');
          else
            next();
        }

      },
      children: [
        {
          path: "",
          name: "ghosts-home",
          component: ghostsHome,
        },
        {
          path: 'ghosthunters',
          component: ghostsGhostHunters,
          children: [
            {
              path: '/',
              name: 'ghosts-ghosthunters-home',
              component: ghostsGhostHuntersNews,
              alias: 'news',
            },
            {
              path: 'history',
              name: 'ghosts-ghosthunters-history',
              component: ghostsGhostHuntersHistory,
            },
            {
              path: 'demons',
              name: 'ghosts-ghosthunters-demons',
              component: ghostsGhostHuntersDemons,
            },
          ]
        },
        {
          path: 'horizon',
          name: 'ghosts-horizon',
          component: ghostsHorizon,
        },
        {
          path: 'daily',
          name: 'ghosts-daily',
          component: ghostsDaily,
        },
        {
          path: 'police',
          name: 'ghosts-police',
          component: ghostsPolice,
        },
        {
          path: 'ouija',
          name: 'ghosts-ouija',
          component: ghostsOuija,
        },
        {
          path: 'tarot',
          name: 'ghosts-tarot',
          component: ghostsTarot,
        },
        {
          path: 'hotel',
          name: 'ghosts-hotel',
          component: ghostsHotel,
        },
        {
          path: 'tourist-office',
          component: ghostsTouristOffice,
          children: [
            {
              path: '/',
              name: 'ghosts-tourist-office-home',
              component: ghostsTouristOfficeHome,
              alias: 'home'
            },
            {
              path: 'places',
              name: 'ghosts-tourist-office-places',
              component: ghostsTouristOfficePlaces,
            },
            {
              path: 'shop',
              name: 'ghosts-tourist-office-shop',
              component: ghostsTouristOfficeShop,
            }
          ]
        },
        {
          path: 'bune',
          name: 'ghosts-hidden-bune',
          component: ghostsTouristOfficeBune,
        },
        {
          path: 'clues',
          name: 'ghosts-clues',
          component: Clues,
        },
        {
          path: 'resolve',
          name: 'ghosts-resolve',
          component: ResolveCase,
        }
      ]
    },
    {
      path: '/egypt/',
      component: egyptRoot,
      props: { gameID: Cases.EGYPT },
      beforeEnter: async (to, from, next) => {
        if (!await isGameAvailable("egypt"))
          next({name: "games"});
        else {
          const targetSection = getSectionFromRoute(store.state.egypt.sections, to);

          if (targetSection?.disabled ?? false)
            next('/egypt');
          else
            next();
        }

      },
      children: [
        {
          path: "",
          name: "egypt-home",
          component: egyptHome,
        },
        {
          path: 'clues',
          name: 'egypt-clues',
          component: Clues,
        },
        {
          path: 'resolve',
          name: 'egypt-resolve',
          component: egyptResolve,
        },
        {
          path: 'security',
          name: 'egypt-security',
          component: egyptSecurity,
        },
        {
          path: 'police',
          name: 'egypt-police',
          component: egyptPolice,
        },
        {
          path: 'suitcase',
          name: 'egypt-suitcase',
          component: egyptSuitcase,
        },
        {
          path: 'phone',
          name: 'egypt-phone',
          component: egyptPhone,
        }, 
        {
          path: 'karnak',
          name: 'egypt-karnak',
          component: egyptKarnak,
        },
        {
          path: 'webmail',
          name: 'egypt-webmail',
          component: egyptWebmail,
        },
        {
          path: 'webmail/:guid',
          name: 'egypt-webmail-reader',
          component: egyptWebmailReader,
          props: true
        },
        {
          path: 'call',
          name: 'egypt-call',
          component: egyptCall,
          props: true          
        },
        {
          path: 'solution',
          name: 'egypt-solution',
          component: ResolveCase,
        }                     
      ]
    },
    {
      path: '/yakuza/',
      component: yakuzaRoot,
      props: { gameID: Cases.YAKUZA },
      beforeEnter: async (to, from, next) => {
        if (!await isGameAvailable("yakuza"))
          next({name: "games"});
        else {
          const targetSection = getSectionFromRoute(yakuzaStore.sections, to);

          if (targetSection?.disabled ?? false)
            next('/yakuza');
          else
            next();
        }

      },
      children: [
        {
          path: "",
          name: "yakuza-home",
          component: yakuzaHome,
        },
        {
          path: 'clues',
          name: 'yakuza-clues',
          component: Clues,
        },
        {
          path: 'resolve',
          name: 'yakuza-resolve',
          component: yakuzaResolve,
        },
        {
          path: "metro/:env",
          name: "yakuza-metro",
          component: yakuzaMetro,
          props: true
        },              
        {
          path: 'audio',
          name: 'yakuza-audio',
          component: yakuzaAudio,
        },
        {
          path: 'hacking',
          name: 'yakuza-hacking',
          component: yakuzaHacking,
        },
        {
          path: 'call',
          name: 'yakuza-call',
          component: yakuzaCall,
        }, 
        {
          path: 'elevator',
          name: 'yakuza-elevator',
          component: yakuzaElevator,
        },
        {
          path: 'safe',
          name: 'yakuza-safe',
          component: yakuzaSafe,
        },
        {
          path: 'arcade',
          name: 'yakuza-arcade',
          component: yakuzaArcade
        }                 
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.restrictedTo === 'police' && store.getters.policeLocked) {
    next({
      name: "maestro-police-home",
      query: { redirect: to.fullPath }
    });
  }
  else {
    next();
  }
})

export default router;
