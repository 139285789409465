<template>
  <div class="res-container" id="res-container">
    <v-dialog v-model="showFullSolutionDialog" persistent max-width="500px">
      <div class="dialog-frame">
        <h1>{{ $t("common.resolveCase.dialogs.showFullSolution.title") }}</h1>
        <p>{{ $t("common.resolveCase.dialogs.showFullSolution.text") }}</p>
        <v-row>
          <v-col>
            <button @click="confirmShowSolution">{{ $t("common.buttons.yes") }}</button>
          </v-col>
          <v-col>
            <button @click="cancelShowSolution">{{ $t("common.buttons.no") }}</button>
          </v-col>
        </v-row>
      </div>
    </v-dialog>    
    <v-overlay absolute class="text-center" :value="isError">
      <div class="dialog-frame">
        <p v-html="$t(`egypt.resolve.error_step_${currentStep+1}`)"></p>
        <button @click="resetError">
          {{ $t("common.buttons.ok") }}
        </button>
      </div>
    </v-overlay>
    <v-overlay absolute class="text-center" :value="isSolved">
      <div class="dialog-frame scroll">
        <p v-html="$t(`egypt.resolve.solved`)"></p>
        <v-row justify="center">
          <v-col
            v-html="solutionHtml"
            cols="12"
            class="solution-text-popup"
          >
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            cols="12"
            sm="8"
            md="8"
          >
            <subscribe :caseID="caseID" v-if="allowedLocales.includes(currentLocale)"></subscribe>
          </v-col>
      </v-row>        
      </div>
    </v-overlay>       
    <canvas id="c"></canvas>
    <div class="res-panel res-loader justify-center align-center" v-if="loader.show">
      <div class="res-loader-text">{{ $t(`egypt.resolve.loading`) }}</div>
      <div class="res-loader-progress">
        <v-progress-linear
          v-model="loader.current"
          color="grey">
        </v-progress-linear>
      </div>
    </div>
    <div class="res-panel justify-center align-center" v-if="showCarrousel">
      <div class="res-panel-prompt">{{ $t(`egypt.resolve.${this.carrousels[currentStep].prompt}`) }}</div>
      <div class="res-carousel-container">
        <v-carousel
          v-model="selection"
          class="res-carousel"
          height="150"
          hide-delimiters
          :show-arrows="false"
          >
          <v-carousel-item
            v-for="img in carrousels[currentStep].images"
            :key="img.id"
            :src="getCarrouselImage(img)"
          ></v-carousel-item>
        </v-carousel>
        <v-hover v-slot="{ hover }">
          <div
            class="res-carousel-arrow res-carousel-left"
            :class="{ hovered: hover }"
            @click="onChangeCarrousel(-1)"
          ></div>
        </v-hover>

        <v-hover v-slot="{ hover }">
          <div
            class="res-carousel-arrow res-carousel-right"
            :class="{ hovered: hover }"
            @click="onChangeCarrousel(1)"
          ></div>
        </v-hover>
      </div>
      <div class="res-carousel-button">
        <v-btn @click="tryUnlockStep" class="res-submit-button">{{ $t("egypt.resolve.submit") }}</v-btn>          
      </div>
    </div>
    <div class="res-panel res-loader justify-center align-center" v-if="showOKTick">
        <img class="puff-out-center img-tick" :src="`${this.casePublicImages}/resolve/tick.png`" />
      </div> 
    <v-container>
      <v-col cols="12" class="d-flex justify-center">
        <button
            class="solution-button"
            @click="toggleSolution"
            v-if="solutionVisible"
          >
            {{ $t(`egypt.resolve.hide_solution`) }}
          </button>
          <button class="solution-button" @click="toggleSolution" v-else>
            {{ $t(`egypt.resolve.view_solution`) }}
          </button>       
      </v-col>
    </v-container>
    <template v-if="solutionVisible">
      <v-row justify="center">
        <v-col
          v-html="solutionHtml"
          cols="12"
          sm="8"
          md="6"
          class="solution-text"
        >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          v-if="evaluateUrl !== undefined"
          cols="12"
          sm="8"
          md="6"
          class="text-center"
        >
          <a :href="evaluateUrl" target="_blank">{{
            $t("common.links.evaluateThisGame")
          }}</a>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col v-for="network in socialNetworks" :key="network.id" cols="auto"
          ><a :href="network.url" target="_blank">
            <img
              :src="getSocialNetworkLogo(network)"
              class="social-network-logo"
          /></a>
        </v-col>
      </v-row>
    </template>
    <audio :src="unlockSoundSrc" ref="unlockSound"></audio>
    <audio :src="backgroundSoundSrc" ref="backgroundSound"></audio>
    <audio :src="correctSoundSrc" ref="correctSound"></audio>
    <audio :src="winSoundSrc" ref="winSound"></audio>  
  </div>
</template>
<script>
  import config from '@/config/';
  import currentGameMixin from "../../mixins/currentGameMixin";
  import axios from "axios";
  import * as THREE from "three";
  import * as TWEEN from '@tweenjs/tween.js';  
  import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
  import { THREEFire } from "../../plugins/THREEFire/Fire.js";
  import Subscribe from "@/components/Subscribe.vue";  

  export default {
    name: "Resolve",
    components: { Subscribe },
    data() {
      return {
        containerId: 'res-container',
        model: 'compressed.glb',
        clock: undefined,
        time: 0,
        manager: undefined,
        scene: undefined,
        camera: undefined,
        renderer: undefined,
        fires: [],
        lights: [],
        modelScale: 1,
        cameraConfig: {
          fov: 30,
          near: 0.15,
          far: 90,
          position: [0, 1.85, 0],
          rotation: [0, Math.PI/2, 0]
        },
        lightConfig: {
          color: '#ffb15c',
          intensity: 0.8,
          distance: 5,
          wobbleAmplitude: 0.15,
          basePosition: [-3.12, 1.77, 1.55],
          baseDeltaX: -7.9,
          countX: 4,
        },
        fireConfig: {
          particleCount: 15,
          radius: 0.10,
          height: 0.60,
          color: '#ffb15c'
        },
        steps: [-7, -13, -18, -23, -23, -40],
        stepDuration: 2000,
        walkBounce: {
          amplitude: 0.015,
          speed: 3
        },
        doors: {
          door1Name: 'Porte1',
          door2Name: 'Porte1001',
          door1: null,
          door2: null,
          openDuration: 4000,
        },
        showCarrousel: false,
        showOKTick: false,
        selection: 0,
        carrousels: [],
        solutionHtml: '',
        solutionVisible: false,
        showFullSolutionDialog: false,
        loader: {
          show: false,
          current: 0
        },
        audioAmbiantVolume: 0.5,
      };
    },
    mixins: [currentGameMixin],
    computed: {
      currentStep: function() {
        return this.$store.getters.egyptResolveStep;
      },
      logosDataPath: function() {
        return `${this.casePublicData}/resolve.json`;
      },
      arrowImagePath: function() {
        return require(`@/assets/images/${this.caseID}/resolve/selection_arrow.svg`);
      },
      logosImagePath: function() {
        return `${this.casePublicImages}/resolve`;
      },      
      modelAsset: function() {
        return `${this.casePublicAssets}/models/${this.model}`;
      },
      texturesAssetFolder: function() {
        return `${this.casePublicAssets}/models/`;
      },
      isError: function() {
        return this.$store.getters.egyptResolveError;
      },
      isSolved: function() {
        return this.$store.getters.egyptResolveSolved;
      },
      solutionSrc: function() {
        return `${this.publicPath}${this.caseID}/solution/facts_${this.$i18n.locale}.html`;
      },
      socialNetworks: function() {
        return this.$store.state.socialNetworks;
      }, 
      unlockSoundSrc: function() {
        return `${this.casePublicAudio}/resolve/unlock.mp3`;
      },
      backgroundSoundSrc: function() {
        return `${this.casePublicAudio}/resolve/bg.mp3`;
      },
      correctSoundSrc: function() {
        return `${this.casePublicAudio}/resolve/nextstep.mp3`;
      },
      winSoundSrc: function() {
        return `${this.casePublicAudio}/resolve/win.mp3`;
      },
      allowedLocales: function() {
        return config.common.showSubscribeForLocales
      },        
    },
    watch: {
      currentStep: function (newValue, oldValue) {
        if(newValue > oldValue) {
          this.showCarrousel = false;
          this.showOKTick = true;          
          this.selection = 0;
          this.nextStep();

          setTimeout(() => {
            this.showOKTick = false;
          }, 2000);
        }
      },
    },    
    methods: {
      openDoor() {
        new TWEEN.Tween(this.doors.door1.position)
          .to({ z: -2.5 }, this.doors.openDuration)
          .delay(1000)
          .easing(TWEEN.Easing.Cubic.InOut)
          .onStart(() => {
            this.$refs.unlockSound.play();
          })
          .onComplete(() => {
            new TWEEN.Tween(this.camera.position)
              .to({ x: this.steps[this.currentStep+1] }, this.stepDuration)
              .easing(TWEEN.Easing.Cubic.InOut)
              .start()
              .onComplete(() => {
                this.$refs.winSound.play();
                this.$store.dispatch("egyptResolveSolve");
                new TWEEN.Tween(this.$refs.backgroundSound)
                  .to({ volume: 0 }, this.stepDuration)
                  .easing(TWEEN.Easing.Cubic.InOut)
                  .start()
                  .onComplete(() => {
                    this.$refs.backgroundSound.pause();
                  })
              });
          })
          .start();

          new TWEEN.Tween(this.doors.door2.position)
            .delay(1000)
            .to({ z: 2.5 }, this.doors.openDuration)
            .easing(TWEEN.Easing.Cubic.InOut)
            .start();          
      },
      nextStep() { 
        if(this.currentStep === 4) {
          this.openDoor();
          return;
        }

        if(this.currentStep > 0) {
          this.$refs.correctSound.play();
        }

        new TWEEN.Tween(this.camera.position)
          .to({ x: this.steps[this.currentStep] }, this.stepDuration)
          .easing(TWEEN.Easing.Cubic.InOut)
          .delay((this.currentStep == 0) ? 2000 : 1000)
          .start()
          .onComplete(() => {
            this.showCarrousel = true;
          });
      },
       
      renderScene() {
        this.renderer.render(this.scene, this.camera);

        const dt = this.clock.getDelta();
        const t = this.clock.getElapsedTime();

        TWEEN.update();

        this.camera.position.y = this.cameraConfig.position[1] + 
                                 Math.sin(this.camera.position.x*this.walkBounce.speed) * this.walkBounce.amplitude;

        this.lights.forEach((l) => {
          if(l.light) {
            l.light.intensity = this.lightConfig.intensity + 
                                Math.sin((l.randomSeed + t) * Math.PI * (2 + l.randomSeed2)) * 
                                Math.cos((l.randomSeed + t) * Math.PI * (1.5 + l.randomSeed2)) * 
                                this.lightConfig.wobbleAmplitude;
          }
        });

        this.fires.forEach((f) => {
          f.mesh.material.update(dt * f.speed);
        });

        this.camera.updateProjectionMatrix();	

        requestAnimationFrame(this.renderScene);
      },
      onLoadStart(url, itemsLoaded, itemsTotal) {
        this.loader.show = true;
        this.loader.current = (100/itemsTotal) * itemsLoaded;
      },
      onLoadComplete( ) {
        this.loader.show = false;
        this.$refs.backgroundSound.loop = true;
        this.$refs.backgroundSound.volume = this.audioAmbiantVolume;
        this.$refs.backgroundSound.play();
      },
      onLoadProgress(url, itemsLoaded, itemsTotal) {
        this.loader.show = true;
        this.loader.current = (100/itemsTotal) * itemsLoaded;
      },
      onLoadError(url) {
        console.log('There was an error loading ' + url);
      },
      init() {
        this.manager = new THREE.LoadingManager();
        this.manager.onStart = this.onLoadStart;
        this.manager.onLoad = this.onLoadComplete;
        this.manager.onProgress = this.onLoadProgress;
        this.manager.onError = this.onLoadError;

        this.clock = new THREE.Clock();

        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x0);

        const canvas = document.getElementById('c');
        this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas });
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(canvas.clientWidth, canvas.clientHeight, false);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
       
        this.camera = new THREE.PerspectiveCamera(
          this.cameraConfig.fov, 
          canvas.clientWidth / canvas.clientHeight,
          this.cameraConfig.near,
          this.cameraConfig.far
        );
        this.camera.position.set(this.cameraConfig.position[0], this.cameraConfig.position[1], this.cameraConfig.position[2]);
        this.camera.rotation.set(this.cameraConfig.rotation[0], this.cameraConfig.rotation[1], this.cameraConfig.rotation[2]);

        for(let l=0; l<this.lightConfig.countX; l++) {
          for(let mirror=0; mirror<2; mirror++) {
            const light = new THREE.PointLight(this.lightConfig.color, this.lightConfig.intensity);

            let x = this.lightConfig.basePosition[0] + this.lightConfig.baseDeltaX * l;
            let y = this.lightConfig.basePosition[1];
            let z = this.lightConfig.basePosition[2] * ((mirror===0) ? 1 : -1);

            light.position.set(x+0.5, y, z);
            light.distance = this.lightConfig.distance;
            light.castShadow = true;
            light.shadow.bias = -1;
            this.scene.add(light);
            
            this.lights.push({
              light: light,
              intensity: this.lightConfig.intensity,
              randomSeed: Math.random() * Math.PI / 2,
              randomSeed2: (Math.random() * 0.2) - 0.1,
            });

            const fire = new THREEFire();
            const fireGeometry = new fire.Geometry(this.fireConfig.radius, this.fireConfig.height, this.fireConfig.particleCount);
            const fireMaterial = new fire.Material({ color: this.fireConfig.color });
            fireMaterial.setPerspective(this.camera.fov, canvas.clientHeight);                   
            let particleFireMesh = new THREE.Points(fireGeometry, fireMaterial);
            particleFireMesh.position.set(x, y, z);
            this.scene.add(particleFireMesh);    
            this.fires.push({
              mesh: particleFireMesh,
              speed: 0.4 + Math.random() * 0.2,
            });                
          }
        }

        let loader = new GLTFLoader(this.manager);

        loader.load(
            this.modelAsset,
            data => {
                var object = data.scene;
                object.position.set(0,0,0);
                object.receiveShadow = true;
                if(this.modelScale) object.scale.set(this.modelScale, this.modelScale, this.modelScale);

                this.scene.add(object);

                const maxAnisotropy = this.renderer.capabilities.getMaxAnisotropy();
                this.scene.traverse( ( object ) => {
                  if ( object.isMesh === true && object.material.map ) {
                    object.material.map.anisotropy = maxAnisotropy;
                    object.receiveShadow = true;
                    object.castShadow = false;

                    if(object.name === this.doors.door1Name) {
                      this.doors.door1 = object;
                    }
                    if(object.name === this.doors.door2Name) {
                      this.doors.door2 = object;
                    }                    
                  }
                } );

                if(this.currentStep >= 1) {
                  this.camera.position.x = this.steps[this.currentStep];
                }                

                this.renderScene();
                this.nextStep();
            }
        );
      },
      resetError: function() {
        this.$store.dispatch("egyptResetResolveError");
      },
      onChangeCarrousel: function(increment) {
        let value = this.selection;

        value += increment;
        if (value < 0) value = this.carrousels[this.currentStep].images.length - 1;
        value = value % this.carrousels[this.currentStep].images.length;
        this.selection = value;
      },      
      getCarrouselImage: function(img) {
        return `${this.logosImagePath}/step${this.currentStep}/${img.res}.jpg`;
      },
      tryUnlockStep: function() {
        this.$store.dispatch("egyptTryUnlockResolveStep", this.selection + 1)
      },
      askShowFullSolution: function() {
        this.showFullSolutionDialog = true;
      },
      toggleSolution: function() {
        if (this.solutionVisible) this.solutionVisible = false;
        else this.askShowFullSolution();
      },
      confirmShowSolution: function() {
        this.showFullSolutionDialog = false;
        this.solutionVisible = true;
      },
      cancelShowSolution: function() {
        this.showFullSolutionDialog = false;
      },
      getSocialNetworkLogo: function(network) {
        return require(`../../assets/images/social_networks/${network.id}_icon.png`);
      },        
      fetchSolution: function() {
        axios
          .get(this.solutionSrc)
          .then(({ data }) => (this.solutionHtml = data))
          .catch(() => {
            this.solutionHtml = "";
          });
      }   
    },
    
    async mounted() {
      this.fetchSolution();

      const { data } = await axios.get(this.logosDataPath);
      this.carrousels = data.steps;
      this.resetError();  

      this.$nextTick(() => {
        this.init();
      });
    },

    beforeDestroy() {
      this.lights.forEach((l) => l.light.dispose());
      this.scene = null;
      this.camera = null;
      this.renderer.dispose();
    }
  };
</script>
<style>

.res-container {
  width:100%;
  position: relative;
}
canvas#c {
  border: solid 1px #666;
  background: #000000;
  width: 100%;
  height: 400px;
}
.solution-button {
  font-family: "Bebas Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  background: #ffc122;
  width: 225px;
  padding: 5px;
  border: 1px solid #ffc122;
  margin: auto;
}

.solution-button:hover {
  background: #ffffff;
}

.res-panel {
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #B6B6B6;
  border-radius: 5px;
  max-width: 380px !important;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 20px;
}

.res-panel.res-loader {
  border: 0;
  top: 180px;
  background: 0;
  text-align: center;
  margin: auto;
}

.img-tick {
  width: 150px;
}

.res-loader-text {
  color: #ccc;
  margin: auto;
}

.res-carousel-container {
  position: relative;
  padding: 24px;
  background: #00000066;
  border: 1px solid #666666;
  border-radius: 5px;
  margin: auto;
  width: 200px;
}

.res-carousel-arrow {
  width: 39px;
  height: 39px;
  position: absolute !important;
  top: 50%;
  cursor: pointer;
  background-image: url(/egypt/assets/images/resolve/selection_arrow.svg);
}
.res-carousel-left {
  left: 0;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.res-carousel-arrow.hovered {
  background-image: url(/egypt/assets/images/resolve/selection_arrow_hover.svg);
}
.res-carousel-right {
  right: 0;
  transform: translate(50%, -50%) rotate(90deg);
}
.res-submit-button {
  background: linear-gradient(180deg, #F95962 1.63%, #C4242D 48.51%, #5B171B 100%);
  border: 1px solid #000000;
  border-radius: 5px;
  width: 120px;
  height: 30px !important;
  margin-top: 16px;
  font-family: 'Arimo';
  margin-bottom: 32px;
  color: #fff !important;
  text-transform: none !important;  
}
.res-panel-prompt {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #fff;
  margin-top: 40px;
  margin-bottom: 24px;
}
.res-carousel-button {
  text-align: center;
}

.solution-text {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: justify;
  text-justify: inter-word;
  text-transform: none;  
}
.solution-text-popup {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: justify;
  text-justify: inter-word;
  text-transform: none;
}

.social-network-logo {
  height: 64px;
}
</style>
