<template>
<v-overlay opacity="0.5" :class="show ? 'requires-no-scroll' : ''" fixed scroll-strategy="block" :value="show" width="200">
  <div id="subscribe-scroll-top"></div>

  <div class="subscribe-panel">
    <!--
      <div id="subscribe-close"  v-if="showClose" @click="show=false">
        <img src="/common/assets/images/close.svg" width="32" height="32" />
      </div>
    -->
    <div class="s-title">
        {{ $t("subscribe.title") }}
    </div>
    <div class="s-subscribe">
      <div v-html="$t('subscribe.email')"></div>
      <div class="s-mt-1">
        <input
          type="email"
          v-model="email"
          :placeholder="$t('subscribe.youremail')"
          @keypress.enter="hideRegisterPopup()"
          :class="{ 'email-error': !isEmailValid }"
        />
      </div>
      <!--
        <div class="s-mt-1">
          <v-checkbox 
            :label="$t('subscribe.receivePromoCodes')" 
            v-model="receivePromoCodes"
          ></v-checkbox>
        </div>
      -->
      <div class="">
        <v-checkbox 
          :label="$t('subscribe.receiveEmails')" 
          v-model="receiveEmails"
        ></v-checkbox>
      </div>          
      <div class="s-mt-1">
        <button 
          class="solution-button" 
          @click="sendSubscription"
          :disabled="!isEmailValid"
        >
            {{ $t("common.buttons.send") }}
        </button>
      </div>
      <div class="s-success" v-if="showSubscribeSuccess">
        {{ $t('subscribe.success') }}
      </div>
      <div class="s-mt-2 align-right">
        <a v-if="showClose" @click="show=false">Ignorer</a>
      </div>
      <!--
      <div class="s-mt-2">
        &#x1F381;<a :href="amazonUrl" target="_blank">{{ $t("subscribe.commentTitle") }}</a> &#x2B50;&#x2B50;&#x2B50;&#x2B50;&#x2B50; {{ $t("subscribe.commentTitle2") }} &#x1F381;
      </div>
      <div class="s-mt-2">
        <a :href="websiteUrl" target="_blank">{{ $t("subscribe.discover") }}</a> {{ $t("subscribe.discover2") }}
      </div>  
      -->    
    </div>
  </div>
</v-overlay>

</template>

<script>
import config from '@/config/'
import { collection, addDoc } from "firebase/firestore"; 

export default {
  data() {
    return {
      show: false,
      showClose: false,
      showSubscribeSuccess: false,
      email: '',
      receiveEmails: false,
      receivePromoCodes: false, 
      regexMail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  props: {
    caseID: String,
  },
  components: {
  },
  methods: {
    scrollTo() {
      setTimeout(() => {
        this.$nextTick(() =>
          document.getElementById('subscribe-scroll-top').scrollIntoView({behavior: 'smooth'})
        );
      });
    },
    sendSubscription: function() {
      if(!this.isEmailValid)
        return;

      addDoc(collection(this.$firestore, "subscriptions"), {
        email: this.email,
        source: "Subscribe",
        case: this.caseID,
        receiveEmails: this.receiveEmails,
        //receivePromoCodes: this.receivePromoCodes,
        date: new Date()
      })

      this.showSubscribeSuccess = true
      this.email = ''

      setTimeout(() => {
        this.show = false
      }, 5000)
    }
  },
  computed: {
    locale: function() {
      return this.$i18n.locale
    },
    amazonUrl: function() {
      return config.common.amazon
    },
    websiteUrl: function() {
      return config.common.website
    },    
    isEmailValid: function() {
      return this.regexMail.test(this.email) && this.receiveEmails
    },    
  },
  mounted() {
    setTimeout(() => {
      this.show = true
      this.scrollTo()
    }, 5000)
    setTimeout(() => {
      this.showClose = true
    }, 10000)
  }
}
</script>


<style scoped lang="scss">




a {
  cursor: pointer;
}

div.align-right {
  text-align: right;

  a {
    color: #ccc;
    text-decoration: underline;
  }
}

.subscribe-panel {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 10px !important;
    border-color: #ffc122;
    border-style: solid;
    border-width: 3px;

    transition: all 0.25s;
    text-align: center;
    color: #fff;



    #subscribe-close {
      position: absolute;
      border-color: #ffc122;
      border: 6px solid #ffc122;
      border-radius: 24px;
      background-color: #000;
      width: 48px;
      height: 48px;
      top: -10px;
      right: -10px;
      padding-top: 1px;
      cursor: pointer;
    }


    .solution-button {
      font-family: "Bebas Neue";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.06em;
      text-align: center;
      background: #ffc122;
      width: 128px;
      padding: 5px;
      border: 1px solid #ffc122;
      border-radius: 2px;
    }

    .solution-button:hover:not([disabled]) {
      background: #ffffff;
      color: #000;
    }

    .solution-button[disabled] {
      background: #999;
    }

    .s-title {
        font-family: "Bebas Neue";
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
    }
   
    .s-mt-1 {
      margin-top:16px;
    }

    .s-mt-2 {
      margin-top:24px;
    }

    .s-success {
      color: #009900;
      font-weight: bold;
    }

    input {
        border-radius: 3px;
        padding: 5px;
        width: 250px;
        color: #ffffff;
        border: 1px solid #ffffff;
    }

    ::v-deep .v-messages {
      min-height: 0px;
    }

    .v-input--checkbox::v-deep {
      max-width: 480px;
      margin:auto;
    }

    .skip-btn {
        color: #fff;

        &:hover {
        color: #ffc122;      
        cursor: pointer;
        }
    }
    .email-error {
        border: 1px solid #999;
        color: #999;
    }    
}
</style>